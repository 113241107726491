import React from "react";
import "aframe";

class Aquaria3D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            background: "#fff",
            cameraZ: 0,
            modelContainerPosition: "0 0 0",
            nothingDrawsNearerPart2Settings: {
                wallOne: {
                    image: "./images/triangle-vr-gallery/ndn-two/one.jpg",
                    position: '-0.3856 -28.4 1.36354',
                    rotation: '0 0 0'
                },
                wallSecond: {
                    image: "./images/triangle-vr-gallery/ndn-two/two.jpg",
                    position: '0.40501 -28.4 0',
                    rotation: '0 -59.99999999999999 0'
                },
                wallThird: {
                    image: "./images/triangle-vr-gallery/ndn-two/three.jpg",
                    position: '-1.12 -28.4 0',
                    rotation: '0 59.99999999999999 0'
                }
            }
        };
    }

    componentDidMount() {
        // Update camera position when the component mounts
        this.updateCameraPosition();
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if cameraZ has changed and update camera position accordingly
        if (this.state.cameraZ !== prevState.cameraZ) {
            this.updateCameraPosition();
        }
    }

    // Function to update camera position based on cameraZ value
    updateCameraPosition() {
        const cameraEntity = document.querySelector('#model-container');
        const { cameraZ } = this.state;
        cameraEntity.object3D.position.set(0, 0, `${cameraZ}`);
    }

    // Event handler for the slider input
    handleSliderChange(event) {
        const newCameraZ = parseFloat(event.target.value);
        this.setState({ cameraZ: newCameraZ });
    }

    render () {
        return (
            <>
                {
                    this.state.cameraZ !== 72 ?
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 5000,
                            bottom: "20px",
                            right: "20px",
                            margin: "0 auto"
                        }}
                    >
                        <input
                            type="range"
                            min="0"
                            // this max number takes the viewer directly in front of the chair image
                            width="100%"
                            max="72"
                            step="1"
                            value={this.state.cameraZ}
                            onChange={(event) => this.handleSliderChange(event)}
                        />
                    </div>:
                    null
                }
                {
                    this.state.cameraZ === 72 ?
                    <div
                        style={
                            {
                                position: "absolute",
                                zIndex: 5000,
                                top: "20px",
                                left: "20px",
                                margin: "0 auto"
                            }
                        }
                    >
                        <button
                            onClick={() =>
                                this.setState(
                                        {
                                            modelContainerPosition: "0 30 0",
                                            background: "rgba(249,31,85,1)"
                                        }
                                    )
                            }
                        >
                            nothing draws nearer
                        </button>
                    </div>:
                    null
                }
                <a-scene
                    embedded
                    style={{ width: "100vw", height: "100vh" }}
                >
                    <a-assets>
                        <img alt="" id="a-third-hit-title" src="./images/a-third-hit/231010_a third hit title.png" />
                        <img alt={"everybodys alone"} id="everybodys-alone" src="./images/triangle-vr-gallery/everybodys alone.jpg" />
                        <img alt={"nothing draws nearer"} id="nothing-draws-nearer" src="./images/triangle-vr-gallery/nothing draws nearer.jpg" />
                        <img alt="" id="1_1" src="./images/triangle-vr-gallery/1_1.png" />
                        <img alt="" id="1_2" src="./images/triangle-vr-gallery/1_2.png" />
                        <img alt="" id="1_3" src="./images/triangle-vr-gallery/1_3.png" />
                        <img alt="" id="1_4" src="./images/triangle-vr-gallery/1_4.png" />
                        <img alt="" id="1_5" src="./images/triangle-vr-gallery/1_5.png" />
                        <img alt="" id="1_6" src="./images/triangle-vr-gallery/1_6.png" />
                        <img alt="" id="1_7" src="./images/triangle-vr-gallery/1_7.png" />
                        <img alt="" id="2_1" src="./images/triangle-vr-gallery/2_1.png" />
                        <img alt="" id="2_2" src="./images/triangle-vr-gallery/2_2.png" />
                        <img alt="" id="2_3" src="./images/triangle-vr-gallery/2_3.png" />
                        <img alt="" id="2_4" src="./images/triangle-vr-gallery/2_4.png" />
                        <img alt="" id="2_5" src="./images/triangle-vr-gallery/2_5.png" />
                        <img alt="" id="2_6" src="./images/triangle-vr-gallery/2_6.png" />
                        <img alt="" id="2_7" src="./images/triangle-vr-gallery/2_7.png" />
                        <img alt="" id="3_1" src="./images/triangle-vr-gallery/3_1.png" />
                        <img alt="" id="3_2" src="./images/triangle-vr-gallery/3_2.png" />
                        <img alt="" id="3_3" src="./images/triangle-vr-gallery/3_3.png" />
                        <img alt="" id="3_4" src="./images/triangle-vr-gallery/3_4.png" />
                        <img alt="" id="3_5" src="./images/triangle-vr-gallery/3_5.png" />
                        <img alt="" id="3_6" src="./images/triangle-vr-gallery/3_6.png" />
                        <img alt="" id="3_7" src="./images/triangle-vr-gallery/3_7.png" />
                        <img alt="" id="ndn-two-one" src="./images/triangle-vr-gallery/ndn-two/one.jpg" />
                        <img alt="" id="ndn-two-two" src="./images/triangle-vr-gallery/ndn-two/two.jpg" />
                        <img alt="" id="ndn-two-three" src="./images/triangle-vr-gallery/ndn-two/three.jpg" />
                    </a-assets>

                    <a-camera id="camera-entity" position="0 1.6 0" />

                    <a-sky color={this.state.background} />

                    <a-entity id="model-container" position={this.state.modelContainerPosition}>

                        <a-entity>
                            {
                                // removes the third hit title and colored tiles when the z position has been raised past the z position of the last tile
                                this.state.cameraZ <= 65 
                                ? <>
                                    {/* ndn, a bunch of color plates, and more ndn */}
                                    <a-entity position="0 0 -5">
                                        {/* <a-image
                                            src="#everybodys-alone"
                                            width="11"
                                            height="2.25"
                                            position="0 1.6 -4.5"
                                            rotation="0 0 0"
                                        /> */}
                                        <a-box color="#fff" width="24" height="24" depth="1" position="0 1.6 -6" />
                                        <a-box color="#93e600" width="24" height="24" depth="1" position="0 1.6 -8" />
                                        <a-box color="#ff0070" width="24" height="24" depth="1" position="0 1.6 -10" />
                                        <a-box color="#00ffc7" width="24" height="24" depth="1" position="0 1.6 -12" />
                                        <a-box color="#93e600" width="24" height="24" depth="1" position="0 1.6 -14" />
                                        <a-box color="#ff0070" width="24" height="24" depth="1" position="0 1.6 -16" />
                                        <a-box color="#00ffc7" width="24" height="24" depth="1" position="0 1.6 -18" />
                                        <a-box color="#93e600" width="24" height="24" depth="1" position="0 1.6 -20" />
                                        <a-box color="#ff0070" width="24" height="24" depth="1" position="0 1.6 -22" />
                                        <a-box color="#00ffc7" width="24"
                                        height="24" depth="1" position="0 1.6 -24" />
                                        <a-box color="#93e600" width="24" height="24" depth="1" position="0 1.6 -26" />
                                        <a-box color="#ff0070" width="24" height="24" depth="1" position="0 1.6 -28" />
                                        <a-box color="#00ffc7" width="24" height="24" depth="1" position="0 1.6 -30" />
                                        <a-box color="#fff" width="24" height="24" depth="1" position="0 1.6 -32" />
                                        {/* this was here once...doesn't seem to fit anymore */}
                                        {/* <a-image
                                            src="#nothing-draws-nearer"
                                            width="11"
                                            height="6.75"
                                            position="0 1.6 -55"
                                            rotation="0 0 0"
                                        /> */}
                                    </a-entity>
                                    {/* a white cube */}
                                    <a-entity id="white-cube">
                                        {/* a side */}
                                        <a-box
                                            id="white-cube-back-wall"
                                            width="150"
                                            height="150"
                                            depth="1"
                                            color="green"
                                            position="0 0 -75"
                                            >
                                        </a-box>
                                        {/* top */}
                                        <a-box
                                            id="white-cube-ceiling"
                                            width="150"
                                            height="1"
                                            depth="150"
                                            color="white"
                                            position="0 75 0"
                                            >
                                        </a-box>
                                        <a-box
                                            id="white-cube-floor"
                                            width="150"
                                            height="1"
                                            depth="150"
                                            color="white"
                                            position="0 -20 0"
                                            >
                                        </a-box>
                                        <a-box
                                            id="white-cube-back-wall"
                                            width="150"
                                            height="150"
                                            depth="1"
                                            color="white"
                                            position="0 0 -75"
                                            >
                                        </a-box>
                                        {/* a side */}
                                        <a-box
                                            width="1"
                                            height="150"
                                            depth="150"
                                            color="white"
                                            position="-75 0 0"
                                            >
                                        </a-box>
                                        {/* a side */}
                                        <a-box
                                            width="1"
                                            height="150"
                                            depth="150"
                                            color="white"
                                            position="75 0 0"
                                            >
                                        </a-box>
                                    </a-entity>
                                    <a-entity id="red-cube">
                                        <a-box
                                            width="40"
                                            height="40"
                                            depth="1"
                                            color="red"
                                            position="0 0 -10"
                                        >
                                        </a-box>
                                        <a-box
                                            id="red-box-bottom"
                                            width="40"
                                            height="1"
                                            depth="40"
                                            color="red"
                                            position="0 20 10"
                                        >
                                        </a-box>
                                        {/* bottom */}
                                        <a-box
                                            id="red-box-bottom"
                                            width="40"
                                            height="1"
                                            depth="40"
                                            color="red"
                                            position="0 -20 10"
                                        >
                                        </a-box>
                                        {/* a side */}
                                        <a-box
                                            width="40"
                                            height="40"
                                            depth="1"
                                            color="red"
                                            position="0 0 30"
                                        >
                                        </a-box>
                                        {/* a side */}
                                        <a-box
                                            width="1"
                                            height="40"
                                            depth="40"
                                            color="red"
                                            position="-20 0 10"
                                        >
                                        </a-box>
                                        {/* a side */}
                                        <a-box
                                            width="1"
                                            height="40"
                                            depth="40"
                                            color="red"
                                            position="20 0 10"
                                        >
                                        </a-box>
                                    </a-entity>
                                    <a-entity
                                        text="value: AQUARIA;"
                                        position="1 1.6 -1"
                                        scale="2"
                                    >
                                    </a-entity>
                                    {/* spinning cubes */}
                                    <a-entity position="2.5 3 -3">
                                        <a-entity position="-.03 -.03 -.03">
                                            <a-box
                                                color="blue"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="orange"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="limegreen"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-entity position=".03 .03 .03">
                                            <a-box
                                                color="hsl(330, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(60, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(180, 75%, 75%)"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        {/* original set */}
                                        <a-entity>
                                            <a-box
                                                color="rgba(80,250,177,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="rgba(249,31,85,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="purple"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                    </a-entity>
                                    <a-entity position="-1 2.5 -5.25">
                                        <a-entity position="-.03 -.03 -.03">
                                            <a-box
                                                color="blue"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="orange"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="limegreen"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-entity position=".03 .03 .03">
                                            <a-box
                                                color="hsl(330, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(60, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(180, 75%, 75%)"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-entity position="-.01 -.01 -.01">
                                            <a-box
                                                color="rgba(80,250,177,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="rgba(249,31,85,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="purple"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-entity position=".01 .01 .01">
                                            <a-box
                                                color="rgba(80,250,177,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="rgba(249,31,85,1)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="purple"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-box
                                            color="rgba(80,250,177,1)"
                                            position="-1 0.5 -3"
                                            width="1"
                                            height="1"
                                            depth="1"
                                            animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                        >
                                        </a-box>
                                        <a-box
                                            color="rgba(249,31,85,1)"
                                            position="-1 0.5 -3"
                                            width="1"
                                            height="1"
                                            depth="1"
                                            animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                        >
                                        </a-box>
                                        <a-box
                                            color="purple"
                                            position="-1 0.5 -3"
                                            rotation="90 90 90"
                                            width="1"
                                            height="1"
                                            depth="1"
                                            animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                        >
                                        </a-box>
                                    </a-entity>
                                        <a-entity position="-.03 -.03 -.03">
                                            <a-box
                                                color="blue"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="orange"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="limegreen"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                        <a-entity position=".03 .03 .03">
                                            <a-box
                                                color="hsl(330, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(60, 75%, 50%)"
                                                position="-1 0.5 -3"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                            <a-box
                                                color="hsl(180, 75%, 75%)"
                                                position="-1 0.5 -3"
                                                rotation="90 90 90"
                                                width="1"
                                                height="1"
                                                depth="1"
                                                animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                            >
                                            </a-box>
                                        </a-entity>
                                    <a-box
                                        color="rgba(80,250,177,1)"
                                        position="-1 0.5 -9"
                                        width="1"
                                        height="1"
                                        depth="1"
                                        animation="property: rotation; dur: 3000; to: 360 360 360; loop: true; easing: linear"
                                    >
                                    </a-box>
                                    <a-box
                                        color="rgba(249,31,85,1)"
                                        position="-1 0.5 -9"
                                        width="1"
                                        height="1"
                                        depth="1"
                                        animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                    >
                                    </a-box>
                                    <a-box
                                        color="purple"
                                        position="-1 0.5 -9"
                                        rotation="90 90 90"
                                        width="1"
                                        height="1"
                                        depth="1"
                                        animation="property: rotation; dur: 3000; to: -360 -360 -360; loop: true; easing: linear"
                                    >
                                    </a-box>
                                </>
                                : <></>
                            }
                        </a-entity>

                        <a-entity
                            position="-5.5 0 -70"
                            rotation="0 -45 0"
                        >
                            <a-entity rotation="0 45 0" position="3 0 -5">
                                <a-image src="#2_1" width="2" height="2" position="-6 1.6 -1.5"></a-image>
                                <a-image src="#2_2" width="2" height="2" position="-4 1.6 -1.5"></a-image>
                                <a-image src="#2_3" width="2" height="2" position="-2 1.6 -1.5"></a-image>
                                <a-image src="#2_4" width="2" height="2" position="0 1.6 -1.5"></a-image>
                                <a-image src="#2_5" width="2" height="2" position="2 1.6 -1.5"></a-image>
                                <a-image src="#2_6" width="2" height="2" position="4 1.6 -1.5"></a-image>
                                <a-image src="#2_7" width="2" height="2" position="6 1.6 -1.5"></a-image>
                            </a-entity>
            
                            <a-entity position="3.75 0 0" rotation="0 -200 0">
                                <a-image src="#1_1" width="2" height="2" position="-6 1.6 -1.5"></a-image>
                                <a-image src="#1_2" width="2" height="2" position="-4 1.6 -1.5"></a-image>
                                <a-image src="#1_3" width="2" height="2" position="-2 1.6 -1.5"></a-image>
                                <a-image src="#1_4" width="2" height="2" position="0 1.6 -1.5"></a-image>
                                <a-image src="#1_5" width="2" height="2" position="2 1.6 -1.5"></a-image>
                                <a-image src="#1_6" width="2" height="2" position="4 1.6 -1.5"></a-image>
                                <a-image src="#1_7" width="2" height="2" position="6 1.6 -1.5"></a-image>
                            </a-entity>
            
                            <a-entity rotation="0 -80 0" position="7 0 -3">
                                <a-image src="#3_1" width="2" height="2" position="-6.5 1.6 -1.5"></a-image>
                                <a-image src="#3_2" width="2" height="2" position="-4.5 1.6 -1.5"></a-image>
                                <a-image src="#3_3" width="2" height="2" position="-2.75 1.6 -1.5"></a-image>
                                <a-image src="#3_4" width="4" height="2" position="-.1 1.6 -1.5"></a-image>
                                <a-image src="#3_5" width="2" height="2" position="2.75 1.6 -1.5"></a-image>
                                <a-image src="#3_6" width="2" height="2" position="4.55 1.6 -1.5"></a-image>
                                <a-image src="#3_7" width="2" height="2" position="6.25 1.6 -1.5"></a-image>
                            </a-entity>
                        </a-entity>

                        <a-entity
                            id="nothing-draws-nearer-part-2"
                        >
                            {
                                Object.keys(this.state.nothingDrawsNearerPart2Settings).map((image, i) => {
                                    return (
                                        <a-image
                                            key={`${image}`}
                                            src={this.state.nothingDrawsNearerPart2Settings[image].image}
                                            rotation={this.state.nothingDrawsNearerPart2Settings[image].rotation}
                                            position={this.state.nothingDrawsNearerPart2Settings[image].position}
                                            // scale="3 2 0.1"
                                            width="3"
                                            height=".5"
                                        />
                                    )
                                })
                            }
                        </a-entity>
      <a-sphere
        color="limegreen"
        radius="1"
        position="0 1.5 -5"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 5000"
      ></a-sphere>

      <a-ring
        color="red"
        radius-inner="1.2"
        radius-outer="1.3"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 2000"
        animation__color="property: material.color; from: red; to: blue; loop: true; dur: 3000"
        animation__axis="property: rotation; to: 0 360 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="yellow"
        radius-inner="1.5"
        radius-outer="1.6"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 3000"
        animation__color="property: material.color; from: yellow; to: purple; loop: true; dur: 3500"
        animation__axis="property: rotation; to: 0 180 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="green"
        radius-inner="1.8"
        radius-outer="1.9"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 4000"
        animation__color="property: material.color; from: green; to: orange; loop: true; dur: 4000"
        animation__axis="property: rotation; to: 0 -90 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="cyan"
        radius-inner="2.1"
        radius-outer="2.2"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 4500"
        animation__color="property: material.color; from: cyan; to: pink; loop: true; dur: 4500"
        animation__axis="property: rotation; to: 0 90 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="magenta"
        radius-inner="2.4"
        radius-outer="2.5"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 5000"
        animation__color="property: material.color; from: magenta; to: green; loop: true; dur: 5000"
        animation__axis="property: rotation; to: 0 -90 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="orange"
        radius-inner="2.7"
        radius-outer="2.8"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 5500"
        animation__color="property: material.color; from: orange; to: red; loop: true; dur: 5500"
        animation__axis="property: rotation; to: 0 90 0; loop: true; dur: 7000"
      ></a-ring>

      <a-ring
        color="blue"
        radius-inner="3.0"
        radius-outer="3.1"
        position="0 1.5 -5"
        rotation="0 0 0"
        animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 6000"
        animation__color="property: material.color; from: blue; to: yellow; loop: true; dur: 6000"
        animation__axis="property: rotation; to: 0 -180 0; loop: true; dur: 7000"
      ></a-ring>

                    </a-entity>
                </a-scene>
            </>
        );
    }
}

export default Aquaria3D;
