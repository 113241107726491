import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Aquaria3D from './Aquaria3D';
// import ConstructingTheSelf from './ConstructingTheSelf';
import DMTSimulator from './DMTSimulator';
import LaCasaDelCiego from './LaCasaDelCiego';
// import ObjectRelations from "./ObjectRelations";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      {/* <Route path="/ConstructingtheSelf" element={<ConstructingTheSelf />} /> */}
      <Route path="/LaCasaDelCiego" element={<LaCasaDelCiego />} />
      {/* <Route path="/ObjectRelations" element={<ObjectRelations />} /> */}
      <Route path="/Aquaria3D" element={<Aquaria3D />} />
      <Route path="/DMTSimulator" element={<DMTSimulator />} />
    </Routes>
  </Router>
);
