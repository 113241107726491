import React from "react";
import "aframe";
import "./ButtonStyles.css"

class LaCasaDelCiego extends React.Component {
    state = {
        ready: null
    }
    render () {
        return (
            <>

            {
                !this.state.ready ?
                <>
                <span style={{fontSize: "80px"}}>MEGA<br />ULTIMATE<br />HORSECOCK</span>
                <br />
                <button
                    onClick={
                        () => {
                            this.setState(
                                {
                                    ready: true
                                }
                            );
                            const audioElement = document.querySelector("#zach");
                            audioElement.components.sound.playSound();
                        }
                    }
                    style={
                        {
                            position: "relative",
                            zIndex: 5000,
                            margin: "0 auto"
                        }
                    }
                >
                    okay, I'm ready
                </button></> :
                null
            }
            <a-scene
                embedded
                style={
                    {
                        width: "100vw",
                        height: "100vh"
                    }
                }
            >
                <a-entity
                    id="zach"
                    geometry="primitive: plane"
                    material="color: blue"
                    position="-10 0 0"
                    sound="src: url(./audio/Funky Sketch.mp3); autoplay: true">
                </a-entity>
            {
                this.state.ready ?
                    <>
                    <a-assets>
                        <img
                            id="ceiling-floor"
                            src="./images/HOTBM/231031_ceiling-floor-lrg.jpg"
                        />
                        <img
                            id="wall-1"
                            src="./images/HOTBM/231031_wall-1-med.jpg"
                        />
                        <img
                            id="wall-2"
                            src="./images/HOTBM/231031_wall-2-med.jpg"
                        />
                        <img
                            id="wall-3"
                            src="./images/HOTBM/231031_wall-3-lrg.jpg"
                        />
                        <img
                            id="wall-4"
                            src="./images/HOTBM/231031_wall-4-lrg.jpg"
                        />

                        <img
                            id="bibleverse"
                            src="./images/HOTBM/231031_bibleverse-med.jpg"
                        />
                        <img
                            id="goochtown"
                            src="./images/HOTBM/231031_goochtown-med.jpg"
                            />
                        <img
                            id="le-gooch"
                            src="./images/HOTBM/231031_le-gooch-med.jpg"
                            />
                        <img
                            id="bathroom"
                            src="./images/HOTBM/231031_bathroom-med.jpg"
                            />
                            <img alt={""} id="matt-walsh" src="./images/horsecock-handjob/matt walsh stick figure.png" />
                            <img alt={""} id="horsecock-handjob-title" src="./images/horsecock-handjob/furry pooter and the horsecock handjob.png" />
                            <img alt={""} id="zach-and-stewart" src="./images/horsecock-handjob/zach and stewart.png" />
                    </a-assets>
                    <a-sky
                        color="red"
                    >
                    </a-sky>
                    {/* ceiling */}
                    <a-image
                        src="#ceiling-floor"
                        width="10"
                        height="10"
                        position="0 10 0"
                        rotation="90 0 0"
                    >
                    </a-image>
                    {/* floor */}
                    <a-image
                        src="#ceiling-floor"
                        width="10"
                        height="10"
                        position="0 0 0"
                        rotation="-90 0 0"
                    >
                    </a-image>
                    {/* wall 1 */}
                    <a-image
                        src="#wall-1"
                        width="10"
                        height="10"
                        position="-5 5 0"
                        rotation="0 90 0"
                    >
                    </a-image>
                    {/* wall 2 */}
                    <a-image
                        src="#wall-2"
                        width="10"
                        height="10"
                        position="5 5 0"
                        rotation="0 -90 0"
                    >
                    </a-image>
                    {/* wall 3 */}
                    <a-image
                        src="#wall-3"
                        width="10"
                        height="10"
                        position="0 5 -5"
                        rotation="0 180 0"
                    >
                    </a-image>
                    {/* wall 4 */}
                    <a-image
                        src="#wall-4"
                        width="10"
                        height="10"
                        position="0 5 5"
                        rotation="0 -180 0"
                    >
                    </a-image>
                    {/* pictures for the wall */}
                    <a-image
                        src="#bibleverse"
                        width=".5"
                        height=".8"
                        position="3.09033 1.37664 -4.95"
                        rotation="0 0 0"
                    >
                    </a-image>

                    <a-image
                        src="#goochtown"
                        width=".5"
                        height=".8"
                        position="3.69033 1.37664 -4.95"
                        rotation="0 0 0"
                    >
                    </a-image>

                    <a-image
                        src="#le-gooch"
                        width=".5"
                        height=".8"
                        position="4.29033 1.37664 -4.95"
                        rotation="0 0 0"
                    >
                    </a-image>

                    <a-image
                        src="#bathroom"
                        width=".5"
                        height=".8"
                        position="2.49033 1.37664 -4.95"
                        rotation="0 0 0"
                    >
                    </a-image>
          <a-image
            src="#matt-walsh"
            width="2"
            height="3"
            position="0 1.6 -1.5"
            rotation="0 180 0"
          />
          <a-image
            src="#horsecock-handjob-title"
            width="2"
            height="3"
            position="2 1.6 -1.5"
            rotation="0 135 0"
          />
          <a-image
            src="#zach-and-stewart"
            width="2"
            height="3"
            position="-2 1.6 -1.5"
            rotation="0 135 0"
          />
          <a-entity
              text="value: ASS-COITUS BUTTER IN THE RAIN;"
              position="2.49033 1.37664 -4.25"
              scale="2 2 2"
              rotation="0 180 0"
          ></a-entity>
          <a-entity
              text="value: a cultural restrospective;"
              position="2.49033 1.07664 -4.25"
              scale="2 2 2"
              rotation="0 180 0"
          >
          </a-entity>
                    <a-camera position="3.29033 1.37664 -4.5" />
                    </>
                    :null
            }
            </a-scene>
            </>
        )
    }
}

export default LaCasaDelCiego;
