import React from 'react';

class DMTSimulator extends React.Component {
    state = {
        ready: "No",
        showCube: false,
        showBubbles: false,
        thirdHitControls: true
    };

    componentDidMount() {
        if (this.state.showBubbles) {
            this.createBubbleGroupsInterval = setInterval(this.createBubbleGroup, 3000);
            this.changeBackgroundInterval = setInterval(this.changeBackground, 2000);
        }
    }
  
    componentWillUnmount() {
        if (this.state.showBubbles) {
            clearInterval(this.createBubbleGroupsInterval);
            clearInterval(this.changeBackgroundInterval);
        }
    }

    createBubbleGroup = () => {
        const scene = document.querySelector("a-scene");

        for (let i = 0; i < 750; i++) {
            const bubble = document.createElement("a-sphere");
            bubble.setAttribute("radius", Math.random() * 0.15 + 0.05);
            bubble.setAttribute(
                "position",
                `${(Math.random() - 0.5) * 40} ${(Math.random() - 0.5) * 40} ${(Math.random() - 0.5) * 40}`
            );
            bubble.setAttribute("color", "#ff0066"); // Set bubble color as needed
            bubble.setAttribute("opacity", "0.9");
            bubble.setAttribute("animation__rise", {
                property: "position",
                to: `${(Math.random() - 0.5) * 40} 2 ${(Math.random() - 0.5) * 40}`,
                loop: true,
                dur: 5000 + Math.random() * 6000,
            });
            bubble.setAttribute("animation__fade", {
                property: "opacity",
                to: 0,
                loop: true,
                dur: 3000,
            });
            scene.appendChild(bubble);
        }
    };

    ready = () => {
        this.setState({
            // ready: "Unequivocally yes",
            // showCube: true, // Reset the showCube state
            nope: "yes"
        });
        alert("A little less eager.");
    }

    wont = () => {
        this.setState({
            ready: "not ready yet, I'm sorry. I really wanted to be. I wanted to be the perfect person, but I'm just not. I'm fucked up. I....I fucked up.",
            showCube: true, // Reset the showCube state
        });
    }

    rotateCube = () => {
        alert("!@#%^&*()-_=+[]{};:'")
        const cubeEntity = document.querySelector('#rotating-cube');
        if (cubeEntity) {
            cubeEntity.setAttribute('animation', {
                property: 'rotation',
                from: cubeEntity.getAttribute('rotation').x + ' ' + cubeEntity.getAttribute('rotation').y + ' ' + cubeEntity.getAttribute('rotation').z,
                to: '0 ' + (cubeEntity.getAttribute('rotation').y + 90) + ' 0',
                dur: 150, // Adjust the duration as needed
                easing: 'linear',
                loop: 'once',
            });

            // Set a timeout to mark the cube as hidden after the specified duration
            setTimeout(() => {
                this.setState({ showCube: false });

                // After hiding the cube, show the bubbles
                this.setState({ showBubbles: true });
                this.createBubbleGroup();
            }, 150);
        }
    };

    render() {
        return (
            <>
                <div id="third-hit-controls">
                    are you ready for a third hit
                    <button onClick={this.ready}>yes, I'm ready now.</button>
                    <button onClick={this.wont}>you're disgusting why is this happening oh god no make it stop i want it to stop</button>
                </div>
                {
                    this.state.ready === "not ready yet, I'm sorry. I really wanted to be. I wanted to be the perfect person, but I'm just not. I'm fucked up. I....I fucked up." ?
                    <>
                        okay you're ready
                        {/* {this.simulator()} */}
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 5000,
                                bottom: "20px",
                                right: "20px",
                                margin: "0 auto"
                            }}
                        >
                        {
                            this.state.showCube ?
                            <>
                                <button
                                    onClick={this.rotateCube}

                                >
                                    Rotate the Cube
                                </button>
                            </>:
                            null
                        }
                        </div>
                        <a-scene
                            embedded
                            style={{ width: "100vw", height: "100vh" }}
                        >
                        <a-assets>
                            <video
                                id="video"
                                // src="./images/rainbow-room/231025_An Encounter (trimmed).mov"
                                src="./images/rainbow-room/231101_an-encounter.mov"
                                // src="https://www.youtube.com/embed/yYWfUPeJNEg"
                                autoPlay
                                loop={true}
                                crossOrigin="anonymous"
                            >
                            </video>
                        </a-assets>
                        <a-entity id="model-container" position="0 0 0">
                            <a-sky color="red" />
                            {
                                this.state.showCube ?
                                <a-box
                                    id="rotating-cube"
                                    position="0 0.51 -31"
                                    rotation="0 20 0"
                                    width="20"
                                    depth="20"
                                    height="20"
                                    color="purple"
                                >
                                </a-box>: <></>
                            }
                            {
                                this.state.showBubbles
                                ? 
                                <a-scene embedded style={{ width: "100vw", height: "100vh" }} >
                                    <a-assets>
                                        <img alt="" id="a-third-hit" src="./images/a-third-hit/231023_a third hit small.jpg" />
                                    </a-assets>
                                    <a-entity id="model-container" position="0 0 0">
                                        <a-sky color="red" />
                      
                                    <a-video
                                        width="440"
                                        height="80"
                                        position="0 0.51 -31"
                                        // width="20"
                                        // height="10"
                                        src="#video"
                                        rotation="0 180 0"
                                        // position="0 2 0"
                                    >
                                    </a-video>
                                        {/* <a-image src={"#a-third-hit"} width="440" height="80" position="0 0.51 -31"></a-image> */}
                                    </a-entity>
                                </a-scene>
                                : null
                            }
                        </a-entity>
                        </a-scene>
                    </>
                    :
                    null
                }
            </>
        );
    }
}

export default DMTSimulator;
