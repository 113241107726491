import React from 'react';
import { Link } from 'react-router-dom';
import aThirdHit from './images/a-third-hit/231023_a third hit small.jpg';
import oneImage from './images/a-third-hit-1.jpg';
import twoImage from './images/a-third-hit-2.jpg';
import thirdImage from './images/a-third-hit-3.jpg';
import fourImage from './images/a-third-hit-4.jpg';
import fiveImage from './images/a-third-hit-5.jpg';
import aThirdHitTitle from './images/a third hit animated title.gif'
import title from './images/a third hit animated title.gif';

import './App.css';

class App extends React.Component {
    state = {
        ready: "No",
        showCube: false,
        showBubbles: false,
        thirdHitControls: true,
        passwordField: ""
    };

    componentDidMount() {
        if (this.state.showBubbles) {
            this.createBubbleGroupsInterval = setInterval(this.createBubbleGroup, 3000);
            this.changeBackgroundInterval = setInterval(this.changeBackground, 2000);
        }
    }
  
    componentWillUnmount() {
        if (this.state.showBubbles) {
            clearInterval(this.createBubbleGroupsInterval);
            clearInterval(this.changeBackgroundInterval);
        }
    }

    createBubbleGroup = () => {
        const scene = document.querySelector("a-scene");

        for (let i = 0; i < 50; i++) {
            const bubble = document.createElement("a-sphere");
            bubble.setAttribute("radius", Math.random() * 0.15 + 0.05);
            bubble.setAttribute(
                "position",
                `${(Math.random() - 0.5) * 40} ${(Math.random() - 0.5) * 40} ${(Math.random() - 0.5) * 40}`
            );
            bubble.setAttribute("color", "#ff0066"); // Set bubble color as needed
            bubble.setAttribute("opacity", "0.9");
            bubble.setAttribute("animation__rise", {
                property: "position",
                to: `${(Math.random() - 0.5) * 40} 2 ${(Math.random() - 0.5) * 40}`,
                loop: true,
                dur: 5000 + Math.random() * 6000,
            });
            bubble.setAttribute("animation__fade", {
                property: "opacity",
                to: 0,
                loop: true,
                dur: 3000,
            });
            scene.appendChild(bubble);
        }
    };

    ready = () => {
        this.setState({
            // ready: "Unequivocally yes",
            // showCube: true, // Reset the showCube state
            nope: "yes"
        });
        alert("A little less eager.");
    }

    wont = () => {
        this.setState({
            ready: "not ready yet, I'm sorry. I really wanted to be. I wanted to be the perfect person, but I'm just not. I'm fucked up. I....I fucked up.",
            showCube: true, // Reset the showCube state
        });
    }

    rotateCube = () => {
        alert("!@#%^&*()-_=+[]{};:'")
        const cubeEntity = document.querySelector('#rotating-cube');
        if (cubeEntity) {
            cubeEntity.setAttribute('animation', {
                property: 'rotation',
                from: cubeEntity.getAttribute('rotation').x + ' ' + cubeEntity.getAttribute('rotation').y + ' ' + cubeEntity.getAttribute('rotation').z,
                to: '0 ' + (cubeEntity.getAttribute('rotation').y + 90) + ' 0',
                dur: 150, // Adjust the duration as needed
                easing: 'linear',
                loop: 'once',
            });

            // Set a timeout to mark the cube as hidden after the specified duration
            setTimeout(() => {
                this.setState({ showCube: false });

                // After hiding the cube, show the bubbles
                this.setState({ showBubbles: true });
                this.createBubbleGroup();
            }, 150);
        }
    };
    handleSubmit = function () {
        // function handleSubmit(e) {
        // // Prevent the browser from reloading the page
        // e.preventDefault();
    
        // // Read the form data
        // const form = e.target;
        // const formData = new FormData(form);
    
        // // You can pass formData as a fetch body directly:
        // fetch('/some-api', { method: form.method, body: formData });
    
        // // Or you can work with it as a plain object:
        // const formJson = Object.fromEntries(formData.entries());
        // console.log(formJson);
        this.setState({hellYeah: "dawg"})
        // alert("Hell yeah dawg")
    }
    render () {
        return (
            <>
            <div className="App">
                <header className="App-header">
                    {/* <img
                        src={aThirdHit}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    /> */}
                        <Link
                            to="/Aquaria3D"
                            className=""
                            style={{
                                marginTop: "10px",
                                fontSize: "20px",
                                color: "000"
                            }}
                        >
                            aquaria: <span 
                            style={{
                                textDecorationLine: "none",
                                fontSize: "15px",
                                color: "#000"
                            }}>a novel in <span 
                            style={{
                                textDecorationLine: "none",
                                fontSize: "15px",
                                color: "#61dafb",
                                animation: "rainbow 2.5s linear reverse",
                                animationIterationCount: "infinite"
                            }}>hypertext</span></span>
                        </Link>
                        <span> </span>

                    {/* <img
                        src={oneImage}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    /> */}
                    {/* <img
                        src={twoImage}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    />
                    <img
                        src={thirdImage}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    />
                    <img
                        src={fourImage}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    />
                    <img
                        src={fiveImage}
                        alt="A Third Hit"
                        width="70% vw"
                        style={{marginTop: "20px"}}
                    /> */}
                    <form
                    style={{opacity: "0"}}
                    onSubmit={this.handleSubmit}>
                        <div
                            style={{marginTop: "20px"}}
                        >
                            <label
                                htmlFor="password"
                            >
                                Password:
                            </label>
                            <input
                                type="text"
                                id="password"
                                name="password"
                                onChange={(e) => {
                                    console.log(this.state.passwordField)
                                    this.setState({
                                        passwordField: e.target.value
                                    })
                                }}
                                placeholder={"gfygdjhfjhgfjdhxbcfjhdbfnvbbnbmvjlkm  jvhigfjkvjbjhcjkcvhxkh45gdhcvfgnhbj hujbhgfjjgfkvvdkdvigtujhu bjhjhghcbhjyhtt          fgtrttrr7ygr6t54yt7ut6y utty8ufgtruift8hgjjvhbjfgfhbvb jnmhkhkjhkuihgkgjdkf hrttu576uyjhuvhgyhbhv     5547865tr7tryhyty5tttrthurrythtjh6yugrtuyuh 8907 mvbkn nbbmn mbngjhjg  bnmbnm bmnn bmnbnmbhbgmkjjm nb  bmjhgjhn hjghiytghjkgjgbjhghfhhknmmnkjmjkknmh uli7yiiij0987654321ufexge               dfdgjhgfjsehgyeshedhsgrdstyeureh56nmnbbmn ,mnb mbbm89076543q121hghdhfbvhghfbbnjvbn jvnbvh n nnbvnbnb bhfgbvotiy9iu88y7hjgkjfksdjiyfktkgnkgnj jghjggjtjhjykhk7yu76jhyjgjbj hiytuhtjyfjhguhgjhbhfb            jhggfjhbgfhfhbghghftghygtg6ryghfhb  b                                    nvbvbgnvnbvmnb mbmvn bnb mnvbnv  b"}
                            />
                        </div>
                        {/* <div>
                            <button
                                type="submit"
                            >
                                Submit
                            </button>
                        </div> */}
                    </form>
                    {
                        this.state.passwordField === "horsecockhandjob69" ?
                        <Link
                            to="/LaCasaDelCiego"
                            style={
                                {
                                    marginTop: "10px",
                                    fontSize: "20px",
                                    color: "#61dafb",
                                    animation: "rainbow 2.5s linear",
                                    animationIterationCount: "infinite"
                                }
                            }
                        >
                            Quinta del HORSECOCK
                        </Link>
                        :null
                    }
                    {/* <input type='text' defaultValue={"HorsecockHandjob69"}></input> */}
                    <div
                        style={
                            {height: "100px"}
                        }
                    >
                    </div>
                </header>
            </div>
            <>
                <div id="third-hit-controls">
                    are you ready for a third hit
                    <button onClick={this.ready}>yes, I'm ready now.</button>
                    <button onClick={this.wont}>you're disgusting why is this happening oh god no make it stop i want it to stop</button>
                </div>
                {
                    this.state.ready === "not ready yet, I'm sorry. I really wanted to be. I wanted to be the perfect person, but I'm just not. I'm fucked up. I....I fucked up." ?
                    <>
                        okay you're ready
                        {/* {this.simulator()} */}
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 5000,
                                margin: "0 auto"
                            }}
                        >
                        {
                            this.state.showCube ?
                            <>
                                <button
                                    onClick={this.rotateCube}

                                >
                                    Rotate the Cube
                                </button>
                            </>:
                            null
                        }
                        </div>
                        <a-scene
                            embedded
                            style={{ width: "100vw", height: "100vh" }}
                        >
                        <a-assets>
                            <video
                                id="video"
                                // src="./images/rainbow-room/231025_An Encounter (trimmed).mov"
                                src="./images/rainbow-room/231101_an-encounter.mov"
                                // src="https://www.youtube.com/embed/yYWfUPeJNEg"
                                autoPlay
                                loop={true}
                                crossOrigin="anonymous"
                            >
                            </video>
                        </a-assets>
                        <a-entity id="model-container" position="0 0 0">
                            <a-sky color="red" />
                            {
                                this.state.showCube ?
                                <a-box
                                    id="rotating-cube"
                                    position="0 0.51 -31"
                                    rotation="0 20 0"
                                    width="20"
                                    depth="20"
                                    height="20"
                                    color="purple"
                                >
                                </a-box>: <></>
                            }
                            {
                                this.state.showBubbles
                                ? 
                                <a-scene embedded style={{ width: "100vw", height: "100vh" }} >
                                    <a-assets>
                                        <img alt="" id="a-third-hit" src="./images/a-third-hit/231023_a third hit small.jpg" />
                                    </a-assets>
                                    <a-entity id="model-container" position="0 0 0">
                                        <a-sky color="red" />
                      
                                    <a-video
                                        width="440"
                                        height="80"
                                        position="0 0.51 -31"
                                        // width="20"
                                        // height="10"
                                        src="#video"
                                        rotation="0 180 0"
                                        // position="0 2 0"
                                    >
                                    </a-video>
                                        {/* <a-image src={"#a-third-hit"} width="440" height="80" position="0 0.51 -31"></a-image> */}
                                    </a-entity>
                                </a-scene>
                                : null
                            }
                        </a-entity>
                        </a-scene>
                    </>
                    :
                    null
                }
            </>
            </>
        );
    }
}

export default App;
